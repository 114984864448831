import { render, staticRenderFns } from "./books_design_patterns.vue?vue&type=template&id=488652fe&scoped=true&lang=pug"
import script from "./books_design_patterns.vue?vue&type=script&lang=ts"
export * from "./books_design_patterns.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488652fe",
  null
  
)

export default component.exports